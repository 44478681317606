<template>
  <div class="p-0 mb-4 mt-4 container">
    <div class="m-0 p-3 text-left" style="font-size: 30px">
      CURRENT OPPORTUNITIES
    </div>
    <div v-if="!jobs.length" class="m-0 p-3 nojobs">
      Δεν υπάρχουν ανοιχτές θέσεις αυτό το διάστημα!
    </div>

    <v-row class="m-0 p-3" v-if="jobs.length > 0">
      <v-col lg="4" cols="12" class="mb-5">
        <div class="sticky">
          <button
            v-for="item in jobs"
            :key="item._id"
            @click="changeJob(item)"
            class="jobButtons"
            :class="{
              activeJobButton: selectedJob && item._id === selectedJob._id,
            }"
          >
            <div class="text-left">
              <p class="m-0 font-weight-bold text-dark">{{ item.jobTitle }}</p>
              <p class="m-0">{{ item.jobField }}</p>
            </div>
            <font-awesome-icon
              :icon="faChevronRight"
              style="font-size: 1.1rem"
              class="text-black align-self-center"
            ></font-awesome-icon>
            <div class="divider"></div>
          </button>
        </div>
      </v-col>
      <v-col v-if="selectedJob" lg="8" cols="12" id="jobContent">
        <v-row style="margin: 0">
          <v-col cols="12" class="description">
            <div class="job_title mb-15 pb-5">
              <h2 class="mb-3">{{ selectedJob.jobTitle }}</h2>
              <span>{{ selectedJob.jobField }}</span>
              <span class="mr-2 ml-2"> &#9679; </span>
              <span>{{ selectedJob.jobType }}</span>
              <span class="mr-2 ml-2"> &#9679; </span>
              <span>{{ selectedJob.location.text }}</span>
            </div>
            <div
              class="mb-10"
              v-for="item in selectedJob.jobContent"
              :key="item.text"
            >
              <h3 class="text-uppercase mb-3">{{ item.title }}</h3>
              <p>{{ item.text }}</p>
              <div v-if="item.bullets.length">
                <ul v-for="(bullet, i) in item.bullets" :key="i">
                  <li>{{ bullet }}</li>
                </ul>
              </div>
            </div>
            <div class="mb-5 pb-2">
              <h2 class="mb-3">Apply Now</h2>
              <span>{{ selectedJob.jobTitle }}</span>
              <span class="mr-2 ml-2"> &#9679; </span>
              <span>{{ selectedJob.jobField }}</span>
              <span class="mr-2 ml-2"> &#9679; </span>
              <span>{{ selectedJob.jobType }}</span>
              <span class="mr-2 ml-2"> &#9679; </span>
              <span>{{ selectedJob.location.text }}</span>
            </div>
            <!-- FORM -->

            <v-form
              ref="form"
              class="jobsForm"
              v-model="isValidForm"
              lazy-validation
            >
              <v-row>
                <v-col lg="8" cols="12">
                  <v-text-field
                    dense
                    outlined
                    v-model="form.firstName"
                    :rules="rules.emptyRules"
                    label="First Name"
                    required
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="form.lastName"
                    :rules="rules.emptyRules"
                    label="Last Name"
                    required
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="form.phoneNumber"
                    :rules="rules.phoneRule"
                    :hide-spin-buttons="true"
                    label="Phone Number"
                    required
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="form.email"
                    :rules="rules.emailRules"
                    label="Email"
                    required
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="form.availabilityToStart"
                    :rules="rules.emptyRules"
                    label="When Could you be available to start?"
                    required
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="form.expectedSalary"
                    :rules="rules.emptyRules"
                    label="What are your Monthly Salary Requirements?"
                    required
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    :rules="rules.emptyRules"
                    v-model="form.linkedIn"
                    label="LinkedIn Profile(URL)"
                  ></v-text-field>
                  <v-textarea
                    outlined
                    :counter="300"
                    v-model="form.whyYou"
                    :rules="rules.openFieldRules"
                    label="Open Comments (eg. why you?)"
                    required
                  ></v-textarea>
                  <v-checkbox v-model="form.terms" :rules="rules.isTrueField">
                    <template v-slot:label>
                      <span
                        >I Agree to the
                        <a
                          style="
                            margin: 0 4px;
                            color: #5b69b2;
                            font-weight: bold;
                          "
                          @click="openTerms('terms-of-use-el')"
                          >Terms & Conditions</a
                        >
                        and
                        <a
                          style="
                            margin: 0 4px;
                            color: #5b69b2;
                            font-weight: bold;
                          "
                          @click="openTerms('privacy-policy-el')"
                          >Privacy Policy</a
                        >
                        of this site</span
                      >
                    </template>
                  </v-checkbox>
                </v-col>
                <!-- IMPORT -->
                <v-col lg="4" cols="12">
                  <div
                    style="position: relative"
                    class="mb-3 custom-file"
                    :style="{
                      color: getInvalidClass(form.cv),
                    }"
                  >
                    <label for="cv" class="" style="width: 100%">
                      <div class="upload" v-if="!form.cv">
                        <div class="absolute_align">
                          <font-awesome-icon
                            :icon="faCloudUpload"
                            style="font-size: 30px"
                          ></font-awesome-icon>
                          <p style="text-align: center">Resume / CV</p>
                          <p style="text-align: center; color: #f9b54a">
                            Upload
                          </p>
                        </div>
                      </div>
                      <div v-else class="upload">
                        <div class="absolute_align">
                          <font-awesome-icon
                            :icon="faCheckCircle"
                            color="#79b533"
                            style="font-size: 30px"
                          ></font-awesome-icon>
                          <p class="uploadText">
                            {{ form.cv.get("file").name }}
                          </p>
                        </div>
                      </div>
                    </label>
                    <input
                      name="cv"
                      style="display: none"
                      id="cv"
                      type="file"
                      @change="setUploadValue($event, 'cv')"
                    />
                  </div>
                  <p class="m-0">
                    Please upload either: pdf, docx, txt, rtf. Max 2MB
                  </p>

                  <div style="position: relative" class="mb-3 custom-file">
                    <label for="coverLetter" class="" style="width: 100%">
                      <div class="upload" v-if="!form.coverLetter">
                        <div class="absolute_align">
                          <font-awesome-icon
                            :icon="faCloudUpload"
                            style="font-size: 30px"
                          ></font-awesome-icon>
                          <p style="text-align: center">
                            Cover Letter (optional)
                          </p>
                          <p style="text-align: center; color: #f9b54a">
                            Upload
                          </p>
                        </div>
                      </div>
                      <div v-else class="upload">
                        <div class="absolute_align">
                          <font-awesome-icon
                            :icon="faCheckCircle"
                            color="#79b533"
                            style="font-size: 30px"
                          ></font-awesome-icon>
                          <p class="uploadText">
                            {{ form.coverLetter.get("file").name }}
                          </p>
                        </div>
                      </div>
                    </label>
                    <input
                      name="coverLetter"
                      style="display: none"
                      id="coverLetter"
                      type="file"
                      @change="setUploadValue($event, 'coverLetter')"
                    />
                  </div>
                  <p class="m-0">
                    Please upload either: pdf, docx, txt, rtf. Max 2MB
                  </p>
                </v-col>
                <v-col lg="8" cols="12">
                  <v-btn
                    @click="submitForm"
                    :disabled="waitingForResponse"
                    color="orange"
                    width="100%"
                    style="text-transform: initial; color: white"
                  >
                    <span v-if="!waitingForResponse">Submit Application</span>
                    <font-awesome-icon
                      v-if="waitingForResponse"
                      style="font-size: 1.5rem"
                      :spin="true"
                      :icon="faSpinner"
                    >
                    </font-awesome-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  faChevronRight,
  faCloudUpload,
  faCheckCircle,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      faChevronRight,
      faCloudUpload,
      faCheckCircle,
      faSpinner,
      selectedJob: null,
      submitted: false,
      isValidForm: false,
      form: null,
      waitingForResponse: false,
      rules: {
        emptyRules: [(v) => (!!v && v.length < 100) || "Field is required"],
        openFieldRules: [(v) => (!!v && v.length < 300) || "Field is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phoneRule: [(val) => (val || "").length > 9 || "Field is required"],
        isTrueField: [(v) => !!v || "Field is required"],
      },
    };
  },
  computed: {
    ...mapState({
      jobs: (state) => state["career/career"].allJobs,
    }),
  },
  methods: {
    ...mapActions({
      getJobs: "career/career/getJobs",
      uploadFile: "career/career/uploadFile",
      addNewApplicant: "career/career/newApplicant",
    }),
    initializeForm() {
      this.$set(this, "form", {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        availabilityToStart: null,
        expectedSalary: null,
        linkedIn: null,
        whyYou: null,
        terms: false,
        coverLetter: null,
        cv: null,
        applicationId: null,
        applicationTitle: null,
      });
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.submitted = false;
      }
    },
    changeJob(job) {
      this.initializeForm();
      this.selectedJob = job;
      this.$nextTick(() => {
        this.form.applicationId = job._id;
        this.form.applicationTitle = job.jobTitle;
        this.scrollToView();
      });
    },
    scrollToView() {
      const scrollId = document.getElementById("jobContent");
      if (scrollId) {
        scrollId.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    openTerms(term) {
      let path = this.$router.resolve({
        path: `legal/${term}`,
      });
      window.open(path.href, "_blank");
    },
    getInvalidClass(input) {
      return this.submitted && !input ? "red" : null;
    },
    setUploadValue(event, file) {
      const emittedFile = event["target"].files;
      if (emittedFile.length > 0) {
        if (!this.isAllowedFormat(emittedFile[0].name)) {
          this.$toast.error(
            "Ο τύπος του αρχείου πρέπει να είναι 'pdf', 'docx', 'txt' ή 'rtf'"
          );
        } else if (this.overSizedFile(emittedFile[0].size)) {
          this.$toast.error(
            "Παρακαλώ βεβαιωθείτε ότι το μέγεθος του αρχείου δεν ξεπερνάει τα 2MB"
          );
        } else {
          let data = new FormData();
          data.append("file", emittedFile[0]);
          this.form[file] = data;
        }
      }
    },
    isAllowedFormat(file) {
      return (
        file.includes(".pdf") ||
        file.includes(".doc") ||
        file.includes(".txt") ||
        file.includes(".rtf")
      );
    },
    overSizedFile(size) {
      return size > 2000000;
    },
    submitForm() {
      this.submitted = true;
      this.isValidForm = this.$refs.form.validate() && !!this.form.cv;
      if (this.isValidForm) {
        this.handleUpload()
          .then((payload) => {
            this.addNewApplicant({ requestBody: payload, vm: this }).then(
              (_) => {
                this.waitingForResponse = false;
                this.initializeForm();
                this.scrollToView();
              }
            );
          })
          .catch((err) => {
            this.waitingForResponse = false;
            this.$toast.error(err);
          });
      } else {
        this.$toast.error("Παρακαλώ συμπληρώστε όλα τα πεδία");
      }
    },
    handleUpload() {
      const cvFile = this.form.cv;
      const coverFile = this.form.coverLetter;
      let cvLink = null;
      let coverLink = null;
      return new Promise((resolve, reject) => {
        this.waitingForResponse = true;
        this.uploadFile({ file: "cv", requestBody: cvFile })
          .then((cvRes) => {
            cvLink = cvRes["link"];
            if (coverFile) {
              this.uploadFile({ file: "coverLetter", requestBody: coverFile })
                .then((coverRes) => {
                  coverLink = coverRes["link"];
                  resolve({
                    ...this.form,
                    cv: cvLink,
                    coverLetter: coverLink,
                  });
                })
                .catch((err) => {
                  this.waitingForResponse = false;
                  reject(err);
                  console.log(err);
                });
            } else {
              resolve({ ...this.form, cv: cvLink });
            }
          })
          .catch((err) => {
            this.waitingForResponse = false;
            reject(err);
            console.log(err);
          });
      });
    },
  },
  created() {
    this.getJobs();
  },
  mounted() {
    this.initializeForm();
  },
};
</script>

<style lang="scss">
@import "@/scss/colors.scss";
p,
li {
  text-align: justify;
}
.nojobs {
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  font-size: 20px;
}
.jobButtons {
  border: none;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0.4rem;
  p {
    margin: 0;
  }
  &:focus {
    background-color: $lightgrey;
    outline: none;
  }
  .divider {
    height: 1px;
    color: black;
    background: black;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    transform: translate(0, 4px);
  }
}
.activeJobButton {
  background-color: $lightgrey;
}
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 90px;
}
.description {
  text-align: left;
  .job_title {
    border-bottom: 3px solid black;
  }
}
#jobContent {
  scroll-margin: 70px;
}
.jobsForm {
  .legendLabel {
    margin: 0;
    padding: 0 5px;
    position: absolute;
    transform: translate(20px, -50%);
    background: white;
    color: $grey;
  }
  .form-group {
    margin-bottom: 2rem;
    input {
      border-radius: 10px;
      height: 50px;
    }
    textarea {
      border-radius: 10px;
    }
  }
  .terms {
    font-weight: 500;
    a {
      color: $blue;
    }
  }
  .custom-file {
    min-width: 200px;
    min-height: 200px;
    border: 2px dashed;
    border-radius: 10px;
    background-color: #f3f3f3;
    cursor: pointer;
  }
  .upload {
    min-width: 200px;
    min-height: 200px;
    cursor: pointer;
    text-align: center;
    .absolute_align {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }
    .uploadText {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 180px;
      overflow: hidden;
      color: black;
      margin: 0;
    }
  }
  .submitButton {
    border-radius: 10px;
    height: 50px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    &:focus {
      color: white;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
