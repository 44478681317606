<template>
  <div class="p-0" style="background-color: white">
    <Header></Header>
    <banners></banners>
    <career-jobs></career-jobs>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import Header from "../../components/Shell-Components/Header.vue";
import BottomBar from "../../components/Shell-Components/BottomBar.vue";
import Banners from "./Career-Components/Banners.vue";
import CareerJobs from "./Career-Components/CareerJobs.vue";
export default {
  components: { Header, BottomBar, Banners, CareerJobs },
  metaInfo: function () {
    return {
      title: "Stoferno.gr | Carrer",
      meta: [
        {
          name: "description",
          content: "Stoferno.gr, carrer, new job oportunities",
        },
      ],
    };
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss"></style>
