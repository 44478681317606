<template>
  <div>
    <v-row class="first_banner primary">
      <v-col
        md="6"
        cols="12"
        class="text d-flex align-center"
        style="text-align: center; padding: 0"
      >
        <div style="text-align: left">
          <p>Let's Do Great Things</p>
          <span>TOGETHER!</span>
        </div>
      </v-col>
      <v-col
        md="6"
        cols="12"
        class="d-flex align-end"
        style="text-align: center; padding: 0 !important"
      >
        <v-img
          src="@/assets/Splash-Screen-graphic.png"
          width="100vw"
          contain
        ></v-img>
      </v-col>
    </v-row>
    <v-row class="second_banner">
      <v-col
        lg="6"
        cols="12"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div class="content">
          <p style="color: grey; font-weight: bold; margin-top: 0">
            OUR MISSION
          </p>
          <p style="font-size: 24px; font-weight: bold; margin-top: 0">
            Create a world where delivery experience matters
          </p>
          <p style="font-size: 16px">
            Our mission is to offer nothing less than the best quality of
            service in the market. This is what unites our team to a common goal
            every day.
          </p>
          <p style="font-size: 16px">
            Stoferno.gr is a startup that was built around the idea of taking
            delivery as a service to a whole other level, offering a completely
            new and innovative dimension to it. We strive to make the life of
            our user’s better by the day.
          </p>
        </div>
      </v-col>
      <v-col
        lg="6"
        cols="12"
        style="
          text-align: center !important;
          display: flex;
          justify-content: center;
        "
      >
        <v-img src="@/assets/HR-Page.png" max-width="60%"></v-img>
      </v-col>
    </v-row>
    <v-row class="third_banner p-1">
      <div
        class="
          col-lg-6 col-12
          text
          justify-content-center justify-content-lg-end
          p-3
        "
      >
        <div class="content d-flex">
          <p
            class="m-0 font-weight-bold"
            style="color: grey; font-size: 18px; text-align: left"
          >
            OUR VALUES
          </p>
          <v-img src="@/assets/HR-Page-2.png" max-width="60%"></v-img>
        </div>
      </div>
      <div class="col-lg-6 col-12 text p-4" style="justify-content: flex-start">
        <div style="width: 100%">
          <ul style="overflow: auto">
            <li
              v-for="(item, i) in ourValuesTabs"
              :key="item.title"
              @click="changeTab(i)"
              :class="{ activeTabClass: i === activeTab }"
            >
              {{ item.title }}
            </li>
          </ul>
          <div style="text-align: left">
            <span style="font-size: 16px">{{
              ourValuesTabs[activeTab].content
            }}</span>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ourValuesTabs: [
        {
          title: "Passion",
          content:
            "One of our most special ingredients that gets us up from bed every morning: We love what we do!",
        },
        {
          title: "Transparency",
          content:
            "We believe in a world of openness; in sharing views and ideas that will help us grow both individually and collectively.",
        },
        {
          title: "Trust",
          content:
            "An integral part of every and any relationship. We believe and rely on each other. This is what makes us strong at the end of the day.",
        },
        {
          title: "Innovation",
          content:
            "We strive to always be one step ahead of everyone else. To achieve that, we believe and invest in our people.",
        },
      ],
      activeTab: 0,
    };
  },
  methods: {
    changeTab(i) {
      this.activeTab = i;
    },
  },
};
</script>

<style lang="scss">
p {
  text-align: justify;
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  text-align: left;
  width: 70%;
}
@media (max-width: 768px) {
  .content {
    width: 100%;
  }
}
.font-24 {
  font-size: 24px;
}
.font-20 {
  font-size: 20px;
}
.first_banner {
  width: 100%;
  margin: 0;
  align-content: flex-start;
  p {
    font-size: 30px;
    color: #ae7100;
    font-weight: bold;
  }
  span {
    margin-top: 20px;
    font-size: 40px;
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
  }
}
.second_banner {
  width: 100%;
  margin: 0;
  p {
    font-size: 20px;
  }
}
.third_banner {
  width: 100%;
  margin: 0;
  background-color: #f0f0f0;
  p {
    color: grey;
    padding-top: 20px;
    font-size: 22px;
  }
  ul {
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    padding: 0;
  }
  li {
    cursor: pointer;
    font-size: 22px;
    padding-right: 20px;
    color: grey;
    font-weight: 500;
  }
  .activeTabClass {
    color: black;
    font-weight: bold;
    font-size: 26px;
  }
}
</style>
